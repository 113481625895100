/* --------------------------------
   special section
-------------------------------- */

.special-section
{
	position: relative;
	background-color: $theme-color;
	color: #fff;

	>[class^="col-xs"],
	>[class*=" col-xs"]
	{
		padding-left: 0;
		padding-right: 0;
	}

	.item
	{
		position: relative;
		@include transition(height 0.3s ease-in-out);
		@include min-screen($md-width+1px) { min-height: 385px; }

		&.first
		{
			@include min-screen($md-width+1px) { border-right: 5px solid #fff; }

			.outer
			{
				padding-left: 15px;
				margin-left: auto;

				@include min-screen($md-width+1px) { padding-right: 30px; }
				@include max-screen($md-width)
				{
					padding-right: 15px;
					margin-right: auto;
				}
			}
		}

		&.second
		{
			@include min-screen($md-width+1px) { border-left: 5px solid #fff; }

			>div
			{
				@include max-screen($md-width) { height: 320px; }
			}

			.outer
			{
				padding-right: 15px;
				margin-right: auto;

				@include max-screen($md-width)
				{
					padding-left: 15px;
					margin-left: auto;
				}
			}
		}

		&.text
		{
			.title { color: #fff; }

			.custom-btn
			{
				border-color: #fff;
				color: #fff;

				&:after { color: #484848; }
				&:hover { background-color: #fff; }
			}
		}

		.outer
		{
			position: relative;
			padding-top: 30px;
			padding-bottom: 30px;
			overflow: hidden;

			@include min-screen($lg-width+1px)       { max-width: 600px; }
			@include screen($md-width+1px,$lg-width) { max-width: 480px; }
			@include max-screen($md-width)           { max-width: 750px; }
		}
	}

	.feedback-bxslider-container
	{
		padding-top: 40px;
		padding-left: 0;
		padding-right: 0;
		text-align: left;

		&:before
		{
			width: 88px;
			height: 65px;
			left: 40px;
			margin-left: 0;
			opacity: 0.47;
		}

		.author
		{
			margin-top: 20px;

			img { margin-bottom: 0; }

			.b-table
			{
				.cell
				{
					&:first-child { width: 100px; }
				}
			}
		}

		.control-wrp
		{
			position: absolute;
			right: 0;
			bottom: 25px;

			@include max-screen(480px)
			{
				position: relative;
				right: auto;
				bottom: auto;
				padding-top: 15px;
				text-align: center;
			}
		}

		.control-btn
		{
			position: relative;
			top: auto;
			margin-top: 0;
		}
	}

	.b-about
	{
		height: 100%;
		position: relative;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;

		.pattern
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #4a5b74;
			opacity: .80;
		}
	}
}