/* --------------------------------
   team
-------------------------------- */

.team-container
{
	&.team-three-columns.team-style-1
	{
		.team-item
		{
			.img-wrap { padding-top: percentage(402/370); }
		}
	}

	&.team-four-columns.team-style-2
	{
		.team-item
		{
			.img-wrap { padding-top: percentage(330/270);}
		}
	}

	&.team-style-1,
	&.team-style-2
	{
		.team-container--inner { margin-bottom: -30px; }

		.team-item
		{
			margin-bottom: 30px;

			@include max-screen($sm-width) { max-width: 400px; }
		}
	}

	&.team-style-1
	{
		.team-item
		{
			.description
			{
				background-color: transparent;
				color: #ddd;
			}

			.description--inner
			{
				top: 100%;
				transform: translateY(-80px);
				transition-delay: 0ms;
			}

			.name,
			.position
			{
				color: #fff;
			}

			p { margin-top: 10px; }

			.social-btns
			{
				a
				{
					color: #ddd;

					&:hover { color: $theme-color; }
				}
			}

			&:hover
			{
				.description { background-color: rgba(55,59,63,0.8); }

				.description--inner
				{
					top: 50%;
					transform: translateY(-50%);
					transition-delay: 50ms;
				}

				.position { color: #9b9b9b; }

				.name
				{
					a:hover { color: $theme-color; }
				}
			}
		}
	}

	&.team-style-2
	{
		.team-item
		{
			.description
			{
				background-color: rgba(#4a5b74,0.82);
				color: #fff;
				opacity: 0;
				visibility: hidden;
			}

			.social-btns a:hover { color: #fff; }

			.name { margin-top: 15px; }

			.position { margin-top: 0; }

			&:hover
			{
				img { transform: scale(1.05); }

				.description
				{
					opacity: 1;
					visibility: visible;
				}

				.name
				{
					a:hover { color: $theme-color; }
				}
			}
		}
	}

	&.team-style-3
	{
		.team-item
		{
			&:first-child { padding-top: 0; }

			&:nth-of-type(2n)
			{
				background-color: #505763;
				color: #ececec;

				.name { color: #fff; }

				.position { color: #a1a0a0; }

				.mail
				{
					color: #ececec;

					&:hover { color: $theme-color; }
				}
			}

			.inner { @include max-screen($sm-width) { max-width: 400px; } }

			.img-wrap { padding-top: percentage(456/370); }

			.name { margin-bottom: 10px; }

			.mail
			{
				display: inline-block;
				line-height: 25px;
				font-size: 14px;
				font-weight: 700;
				color: $theme-color;

				i
				{
					display: inline-block;
					vertical-align: top;
					font-size: 25px;
					margin-right: 10px;
				}

				&:hover { color: #b5b5b5; }
			}

			@include min-screen($md-width+1px)
			{
				padding-top: 100px;
				padding-bottom: 100px;
			}

			@include screen($sm-width+1px,$md-width)
			{
				padding-top: 80px;
				padding-bottom: 80px;
			}

			@include max-screen($sm-width)
			{
				padding-top: 60px;
				padding-bottom: 60px;
			}
		}

		.social-btns
		{
			margin-top: 30px;
			margin-bottom: 30px;

			a
			{
				color: #c4c4c4;

				&:hover { color: $theme-color; }
			}
		}

		.control-btn { margin-top: 30px; }
	}

	.team-item
	{
		overflow: hidden;

		.inner
		{
			position: relative;
			background-color: #4a5b74;
			@include transition(background-color 300ms ease-in-out);
		}

		.img-wrap
		{
			position: relative;
			overflow: hidden;
			width: 100%;
			height: 0;
			opacity: 0.85;
			@include transition(opacity 300ms ease-in-out);
		}

		img
		{
			transform: translateZ(0);
			@include transition(transform 300ms ease-in-out);
		}

		img,
		.description,
		.description--inner
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.description,
		.description--inner
		{
			padding: 20px;
			overflow: hidden;
			@include transition(
				background-color 300ms ease-in-out,
				visibility 300ms ease-in-out,
				color 300ms ease-in-out,
				opacity 300ms ease-in-out,
				top 300ms ease-in-out,
				transform 300ms ease-in-out
			);
		}

		.description--inner
		{
			top: 50%;
			height: auto;
			transform: translateY(-50%);
		}

		.name
		{
			color: #444;
			margin-bottom: 5px;
		}

		.position
		{
			line-height: 1;
			text-transform: uppercase;
			margin-top: 10px;
			color: #9b9b9b;
		}
	}
}