/* --------------------------------
   form
-------------------------------- */

form
{
	position: relative;

	.input-wrp
	{
		display: block;
		position: relative;
		margin-bottom: 15px;

		input,
		textarea
		{
			display: block;
			width: 100%;
			background: none;
			background: transparent;
			border: none;
			box-shadow: none;
			outline: none;
			border-radius: 0;
			padding-top: 10px;
			padding-bottom: 10px;
			line-height: 1.4;
			font-size: 13px;
			@include transition( background-color 0.3s ease-in-out, color 0.3s ease-in-out );

			&::-webkit-input-placeholder { @include transition(color 0.3s ease-in-out); }
			&::-moz-placeholder          { @include transition(color 0.3s ease-in-out); }
			&:-moz-placeholder           { @include transition(color 0.3s ease-in-out); }
			&:-ms-input-placeholder      { @include transition(color 0.3s ease-in-out); }
		}

		textarea
		{
			resize: vertical;
			min-height: 145px;
		}

		span
		{
			display: block;
			padding-top: 2px;
			border: 1px solid #dbdee3;
			@include transition(border-color 0.3s ease-in-out);
		}

		input:hover + span,
		input:focus + span,
		textarea:hover + span,
		textarea:focus + span
		{
			border-color: #cacaca
		}
	}

	button[type=submit]
	{
		main & { margin-top: 10px; }
	}
}