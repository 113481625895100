/* --------------------------------
   practices
-------------------------------- */

#s-practices {}

.practices-container
{
	&.practices-four-columns.practices-style-1
	{
		.row
		{
			margin-left: 0;
			margin-right: 0;
		}

		[class^="col-xs"],
		[class*=" col-xs"]
		{
			padding-left: 0;
			padding-right: 0;
		}

		.practices-item
		{
			.img-wrap { padding-top: 100%; }
		}
	}

	&.practices-four-columns.practices-style-2 
	{
		.practices-item
		{
			.img-wrap { padding-top: percentage(230/270); }
		}
	}

	&.practices-style-1
	{
		.practices-item
		{
			.inner a
			{
				color: #fff;
				padding: 20px;

				&:before
				{
					display: inline-block;
					vertical-align: bottom;
					content: "";
					width: 0;
					height: 100%;
				}

				> div
				{
					display: inline-block;
					vertical-align: bottom;
					width: 100%;
				}
			}

			.b-table
			{
				.first { width: 70%; }

				.second { width: 30%; }
			}

			.title { color: inherit; }
		}
	}

	&.practices-style-2
	{
		.practices-container--inner { margin-bottom: -30px; }

		.practices-item
		{
			margin-bottom: 30px;

			.description
			{
				padding-top: 20px;
				color: #9b9b9b;
			}

			.title
			{
				color: #444;
				margin-bottom: 5px;
			}

			p { margin-top: 0; }

			&:hover
			{
				.title
				{
					a:hover { color: $theme-color; }
				}
			}
		}
	}

	.practices-container--inner { overflow: hidden; }

	.practices-item
	{
		max-width: 500px;

		.inner
		{
			position: relative;
			background-color: #4a5b74;
			@include transition(background-color 300ms ease-in-out);
		}

		.img-wrap
		{
			position: relative;
			overflow: hidden;
			width: 100%;
			height: 0;
			opacity: 0.5;
			@include transition(opacity 300ms ease-in-out);
		}

		img,
		.inner a
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		a:hover { text-decoration: none; }

		&:hover
		{
			.inner:hover
			{
				background-color: $theme-color;

				.img-wrap { opacity: 0.25; }
			}
		}
	}
}