/* --------------------------------
   counters
-------------------------------- */

.counters-container
{
	position: relative;
	margin-top: 30px;
	margin-bottom: 30px;
	line-height: 0;
	font-size: 0;
	letter-spacing: -1px;

	&:first-child { margin-top: 0; }
	&:last-child  { margin-bottom: 0; }

	&.counters-style-2
	{
		border: 5px solid $theme-color;
		padding: 50px 15px 60px;
		text-align: center;
	}

	@include max-screen(480px) { text-align: center; }

	.counters-container--inner
	{
		margin-right: -20px;
		margin-bottom: -30px;
		margin-left: -20px;

		@include max-screen($sm-width)
		{
			margin-left: -15px;
			margin-right: -15px;
		}

		@include max-screen(480px)
		{
			display: inline-block;
			vertical-align: top;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.title
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		margin: -20px 0 0;
		text-align: center;

		span
		{
			background-color: #fff;
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	.counter-item
	{
		display: inline-block;
		vertical-align: top;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 30px;
		text-align: left;
		letter-spacing: 0;

		@include max-screen($sm-width)
		{
			padding-left: 15px;
			padding-right: 15px;
		}

		@include max-screen(480px) { display: block; }
	}

	.count
	{
		display: block;
		line-height: 1;
		font-size: 60px;
		font-weight: 700;
		color: $theme-color;

		&:after
		{
			content: attr(data-after);
			display: inline-block;
		}
	}

	.count-details { margin-top: 5px; }
}