/* --------------------------------
   404
-------------------------------- */

#s-404
{
	font-size: 20px;
	font-family: $base-fontFamily-2;
	color: #444;
	text-align: center;

	@include min-screen($md-width) { padding-bottom: 150px; }

	p { @include min-screen(481px) { margin: 40px 0; } }

	.title
	{
		line-height: 1;
		font-size: 35px;
		margin-top: 30px;

		@include min-screen(481px) { margin-top: 60px; }
	}

	.custom-btn { margin-top: 10px; }
}