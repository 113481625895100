/* --------------------------------
   services
-------------------------------- */

#s-services { }

.services-container
{
	position: relative;

	&.services-style-1
	{
		.services-container--inner { margin-bottom: -50px; }

		.service-item
		{
			padding-left: 75px;
			margin-bottom: 50px;

			i.ico
			{
				margin-left: -75px;
				width: 60px;

				&.ico-1:before
				{
					width: 41px;
					height: 58px;
					background-image: url(../img/ico/ico_s_1_1.png);
				}

				&.ico-2:before
				{
					width: 57px;
					height: 49px;
					background-image: url(../img/ico/ico_s_1_2.png);
				}

				&.ico-3:before
				{
					width: 58px;
					height: 50px;
					background-image: url(../img/ico/ico_s_1_3.png);
				}

				&.ico-4:before
				{
					width: 55px;
					height: 57px;
					background-image: url(../img/ico/ico_s_1_4.png);
				}

				&.ico-5:before
				{
					width: 57px;
					height: 53px;
					background-image: url(../img/ico/ico_s_1_5.png);
				}

				&.ico-6:before
				{
					width: 55px;
					height: 53px;
					background-image: url(../img/ico/ico_s_1_6.png);
				}
			}

			.inner { padding-left: 15px; }

			&:hover
			{
				.title { color: $theme-color; }
			}
		}
	}

	&.services-style-2
	{
		.services-container--inner { margin-bottom: -30px; }

		.service-item
		{
			padding-left: 75px;
			margin-bottom: 30px;

			i.ico
			{
				margin-left: -75px;
				width: 60px;

				&.ico-1:before
				{
					width: 41px;
					height: 58px;
					background-image: url(../img/ico/ico_s_1_1.png);
				}

				&.ico-2:before
				{
					width: 57px;
					height: 49px;
					background-image: url(../img/ico/ico_s_1_2.png);
				}

				&.ico-3:before
				{
					width: 58px;
					height: 50px;
					background-image: url(../img/ico/ico_s_1_3.png);
				}

				&.ico-4:before
				{
					width: 55px;
					height: 57px;
					background-image: url(../img/ico/ico_s_1_4.png);
				}

				&.ico-5:before
				{
					width: 57px;
					height: 53px;
					background-image: url(../img/ico/ico_s_1_5.png);
				}

				&.ico-6:before
				{
					width: 55px;
					height: 53px;
					background-image: url(../img/ico/ico_s_1_6.png);
				}
			}

			.inner
			{
				border-left: 2px solid #e0e0e0;
				padding-left: 13px;
			}

			&:hover
			{
				.title { color: $theme-color; }
			}
		}
	}

	&.services-style-3
	{
		.services-container--inner { margin-bottom: -50px; }

		.service-item
		{
			padding-left: 75px;
			margin-bottom: 50px;

			i.ico
			{
				width: 70px;
				height: 70px;
				margin-left: -75px;
				background-color: $theme-color;

				&:before
				{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
				}

				&:after
				{
					content: "";
					position: absolute;
					top: 100%;
					left: 0;
					width: 100%;
					height: 2px;
					margin-top: 2px;
					background-color: $theme-color;
				}

				&.ico-1:before
				{
					width: 29px;
					height: 41px;
					background-image: url(../img/ico/ico_s_2_1.png);
				}

				&.ico-2:before
				{
					width: 40px;
					height: 35px;
					background-image: url(../img/ico/ico_s_2_2.png);
				}

				&.ico-3:before
				{
					width: 40px;
					height: 35px;
					background-image: url(../img/ico/ico_s_2_3.png);
				}

				&.ico-4:before
				{
					width: 40px;
					height: 42px;
					background-image: url(../img/ico/ico_s_2_4.png);
				}

				&.ico-5:before
				{
					width: 40px;
					height: 38px;
					background-image: url(../img/ico/ico_s_2_5.png);
				}

				&.ico-6:before
				{
					width: 41px;
					height: 39px;
					background-image: url(../img/ico/ico_s_2_6.png);
				}
			}

			.inner { padding-left: 25px; }

			&:hover
			{
				.title { color: $theme-color; }
			}
		}
	}

	&.services-style-4
	{
		padding-top: 50px;

		@include min-screen($md-width+1px) { margin-top: -85px; }

		&:before,
		&:after
		{
			content: "";
			position: absolute;
			top: 0;
		}

		&:before
		{
			left: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 50px 50px;
			border-color: transparent transparent $theme-color transparent;
		}

		&:after
		{
			left: 50px;
			right: 0;
			height: 50px;
			background-color: $theme-color;
		}

		.services-container--inner
		{
			padding-left: 15px;
			padding-right: 15px;
			padding-bottom: 10px;
			background-color: $theme-color;

			@include min-screen($md-width+1px)
			{
				padding-left: 40px;
				padding-right: 40px;
			}
		}

		.service-item
		{
			padding-left: 75px;
			margin-bottom: 40px;
			color: rgba(#fff,0.75);

			i.ico
			{
				margin-left: -75px;

				&.ico-1:before
				{
					width: 41px;
					height: 58px;
					background-image: url(../img/ico/ico_s_1.png);
				}

				&.ico-2:before
				{
					width: 54px;
					height: 46px;
					background-image: url(../img/ico/ico_s_2_2.png);
				}

				&.ico-3:before
				{
					width: 54px;
					height: 48px;
					background-image: url(../img/ico/ico_s_2_3.png);
				}

				&.ico-4:before
				{
					width: 55px;
					height: 57px;
					background-image: url(../img/ico/ico_s_4.png);
				}

				&.ico-5:before
				{
					width: 53px;
					height: 49px;
					background-image: url(../img/ico/ico_s_2_5.png);
				}

				&.ico-6:before
				{
					width: 53px;
					height: 51px;
					background-image: url(../img/ico/ico_s_2_6.png);
				}
			}

			.title { color: #fff; }
		}
	}

	&.services-style-5
	{
		background-color: $theme-color;

		.services-container--inner { margin-bottom: -50px; }

		.service-item
		{
			padding-left: 75px;
			margin-bottom: 50px;
			color: rgba(#fff,0.75);

			i.ico
			{
				margin-left: -75px;
				width: 60px;

				&.ico-1:before
				{
					width: 41px;
					height: 58px;
					background-image: url(../img/ico/ico_s_2_1.png);
				}

				&.ico-2:before
				{
					width: 57px;
					height: 49px;
					background-image: url(../img/ico/ico_s_2_2.png);
				}

				&.ico-3:before
				{
					width: 58px;
					height: 50px;
					background-image: url(../img/ico/ico_s_2_3.png);
				}

				&.ico-4:before
				{
					width: 55px;
					height: 57px;
					background-image: url(../img/ico/ico_s_2_4.png);
				}

				&.ico-5:before
				{
					width: 57px;
					height: 53px;
					background-image: url(../img/ico/ico_s_2_5.png);
				}

				&.ico-6:before
				{
					width: 55px;
					height: 53px;
					background-image: url(../img/ico/ico_s_2_6.png);
				}
			}

			.inner
			{
				padding-left: 14px;
				border-left: 1px solid rgba(#fff,0.15);

				&:before
				{
					content: "";
					position: absolute;
					top: 0;
					left: 2px;
					bottom: 0;
					border-left: 1px solid rgba(#fff,0.15);
				}
			}

			.title { color: #fff; }
		}
	}

	.services-container--inner { overflow: hidden; }

	.service-item
	{
		position: relative;
		width: 100%;

		i.ico
		{
			position: relative;
			float: left;
			display: inline-block;
			vertical-align: top;
			text-align: center;

			@include transition(all 300ms ease-in-out);

			&:before
			{
				content: "";
				display: inline-block;
				vertical-align: top;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: 100% 100%;
			}
		}

		.inner { position: relative; }

		.title { margin-bottom: 10px; }

		p
		{
			margin-top: 10px;
			margin-bottom: 10px;

			&:last-child { margin-bottom: 0; }
		}
	}
}