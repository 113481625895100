body
{
	@include max-screen($sm-width) { padding-top: 80px; }
}

/* --------------------------------
   header
-------------------------------- */

#header
{
	z-index: 6;

	@include min-screen($sm-width+1px)
	{
		padding-top: 30px;

		&.header-style-1
		{
			padding-bottom: 30px;

			.line { margin-bottom: 10px; }

			#navigation
			{
				float: right;

				li
				{
					&:after { margin-top: 34px; }
				}

				.submenu
				{
					li
					{
						&:hover .submenu { margin-right: -10px; }
					}

					.submenu
					{
						left: auto;
						right: 100%;
						margin-right: 20px;
					}
				}
			}
		}

		&.header-style-2
		{
			padding-bottom: 20px;

			#header-logo { margin-top: -10px; }

			.line
			{
				padding-bottom: 29px;
				border-bottom: 1px solid #ededed;
				margin-bottom: 20px;
			}

			#navigation
			{
				float: left;

				li
				{
					&:after { margin-top: 25px; }
				}

				.submenu
				{
					li
					{
						&:hover .submenu { margin-left: -10px; }
					}

					.submenu
					{
						left: 100%;
						margin-left: 20px;
					}
				}
			}
		}

		&.header-style-3
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;

			#header-logo
			{
				float: none;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 2px;
				background: url(../img/logo_2.png);
			}

			.inner
			{
				@include transition(none);

				&.fixed
				{
					position: fixed;
					top: 0;
					left: 0;
					width: 100%;
					background-color: #3f3f3f;
					@include transition(background-color 0.3s ease-in-out);
				}
			}

			#navigation
			{
				margin-top: 0;
				padding-top: 28px;
				padding-bottom: 28px;
				text-align: center;

				ul { }

				li
				{
					&:after
					{
						height: 3px;
					}
				}

				a { color: #fff; }
			}
		}

		.line { text-align: right; }

		.social-btns
		{
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;
		}
	}

	@include max-screen($sm-width)
	{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 80px;
		padding-top: 15px;
		padding-bottom: 15px;
		background-color: #3f3f3f;

		.line
		{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			text-align: center;
		}

		.social-btns { margin-bottom: 20px; }

		.inner
		{
			display: none;
			position: absolute;
			top: 85px;
			top: 65px;
			left: 0;
			bottom: 0;
			width: 100%;
			padding-left: 15px;
			padding-right: 15px;
		}

		&.expanded
		{
			height: 100%;

			>div { height: 100%; }

			.inner
			{
				display: block;
				padding-bottom: 85px;
			}
		}
	}

	@include screen($sm-width+1px,$md-width)
	{
		&.header-style-3
		{
			#navigation
			{
				li { margin-left: 5px; }
			}
		}
	}

	>div
	{
		position: relative;
		padding-left: 15px;
		padding-right: 15px;
	}

	.line
	{
		line-height: 1.2;
		font-size: 12px;
		font-weight: 700;
	}

	.header-contact
	{
		display: inline-block;
		vertical-align: middle;

		span
		{
			border-left: 1px solid #d4d4d4;
			padding-left: 10px;
			margin-left: 10px;

			&:first-child
			{
				border-left: none;
				padding-left: 0;
				margin-left: 0;
			}

			i
			{
				display: inline-block;
				vertical-align: middle;
				line-height: 1;
				font-size: 15px;
				margin-right: 5px;
				transform: rotate(20deg);
			}
		}
	}
}

#navigation
{
	margin-top: 15px;
	text-align: center;

	@include max-screen($sm-width)
	{
		height: 100%;
		margin-top: 0;
		overflow-y: auto;

		&:before
		{
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 0;
			height: 100%;
			margin-left: -4px;
		}
	}

	>ul
	{
		display: inline-block;
		vertical-align: middle;

		@include min-screen($sm-width+1px)
		{
			display: block;
		}

		@include max-screen($sm-width)
		{
			width: 100%;
			max-width: 200px;
			margin-top: -5px;
		}
	}

	ul
	{
		line-height: 0;
		font-size: 0;
		letter-spacing: -1px;
		white-space: nowrap;
	}

	li
	{
		position: relative;
		display: block;

		&:first-child { margin-left: 0; }

		@include min-screen($sm-width+1px)
		{
			display: inline-block;
			vertical-align: middle;
			margin-left: 20px;

			&:after
			{
				content: "";
				display: block;
				position: absolute;
				top: 100%;
				left: 49%;
				right: 49%;
				height: 5px;
				pointer-events: none;
				opacity: 0;
				background-color: $theme-color;
				@include transition(
					opacity 0.5s ease 0s,
					left 0.5s ease 0s,
					right 0.5s ease 0s
				);
			}

			&:hover:after,
			&.current:after,
			&.active:after
			{
				left: 0px;
				right: 0px;
				opacity: 1;
			}

			&:hover
			{
				>.submenu
				{
					padding-top: 40px;
					visibility: visible;
					opacity: 1;
				}
			}
		}

		@include max-screen($sm-width)
		{
			margin-top: 5px;

			&.drop_active>a,
			&.current>a,
			&.active>a,
			&:hover>a
			{
				background-color: #4a4a4a;
			}
		}
	}

	a
	{
		position: relative;
		display: block;
		line-height: 1;
		font-size: 18px;
		font-weight: 700;
		letter-spacing: 0;
		white-space: normal;
		color: #fff;
		padding: 5px;

		&:hover { text-decoration: none; }

		@include min-screen($sm-width+1px)
		{
			font-size: 14px;
			color: #444;
		}
	}

	.submenu
	{
		display: none;

		@include min-screen($sm-width+1px)
		{
			display: block;
			position: absolute;
			top: 100%;
			left: 50%;
			width: 200px;
			margin-left: -100px;
			padding-top: 60px;
			visibility: hidden;
			opacity: 0;
			z-index: 3;
			@include transition(
				opacity 0.2s ease-in-out,
				margin-left 0.2s ease-in-out,
				margin-right 0.2s ease-in-out,
				padding-top 0.2s ease-in-out,
				visibility 0.2s ease-in-out
			);

			ul
			{
				background-color: #3f3f3f;
				padding-top: 25px;
				padding-bottom: 25px;
				text-align: left;
			}

			li
			{
				display: block;
				margin: 0;

				&:after { content: none; }

				&.has-children > a:after
				{
					content: "";
					position: absolute;
					top: 50%;
					right: 20px;
					width: 0;
					height: 0;
					margin-top: -3px;
					border-style: solid;
					border-width: 3px 0 3px 4px;
					border-color: transparent transparent transparent $theme-color;
				}

				&:hover > a,
				&.current > a
				{
					background-color: #4a4a4a;
				}

				&:hover .submenu { padding-top: 0; }
			}

			a
			{
				color: #fff;
				padding: 8px 25px;
			}

			.submenu
			{
				top: 10px;
				padding-top: 0;

				ul { background-color: $theme-color; }
			}
		}
	}
}

#header-logo
{
	width: 253px;
	height: 50px;
	background-image: url(../img/logo_2.png);

	@include min-screen($sm-width+1px)
	{
		float: left;
		background-image: url(../img/logo_1.png);
	}
}

.site-logo
{
	display: block;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	background-repeat: no-repeat;
}

#menu-toggler
{
	position: absolute;
	top: 5px;
	right: 15px;
	padding: 19px 10px;

	@include min-screen($sm-width+1px) { display: none; }

	span
	{
		display: block;
		position: relative;

		&:before,
		&:after
		{
			content: "";
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #ffffff;
		}

		&:before { top: -6px; }
		&:after { top: 6px; }
	}

	span,
	span:before,
	span:after
	{
		height: 3px;
		width: 20px;
		background-color: #ffffff;

		@include transition(all 400ms ease-in-out);
	}

	&.active
	{
		span
		{
			background-color: transparent;

			&:before,
			&:after
			{
				top: 0;

				-webkit-transform-origin: 50% 50%;
				-moz-transform-origin: 50% 50%;
				-ms-transform-origin: 50% 50%;
				-o-transform-origin: 50% 50%;
				transform-origin: 50% 50%;
			}

			&:before
			{
				-webkit-transform: rotate(225deg);
				-ms-transform: rotate(225deg);
				-o-transform: rotate(225deg);
				transform: rotate(225deg);
			}

			&:after
			{
				-webkit-transform: rotate(-225deg);
				-ms-transform: rotate(-225deg);
				-o-transform: rotate(-225deg);
				transform: rotate(-225deg);
			}
		}
	}
}


/* --------------------------------
   headline
-------------------------------- */

#headline
{
	font-family: $base-fontFamily-2;
	background-color: #333;
	border-bottom: 8px solid $theme-color;
	padding: 45px 0;
}

#page-title
{
	line-height: 1;
	color: #fff;

	@include max-screen($md-width) { margin-bottom: 20px; }
}

#headline-text
{
	font-size: 14px;
	color: #c2c2c2;
}