/* --------------------------------
   accordion
-------------------------------- */

.accordion-container
{
	margin-top: 20px;
	margin-bottom: 20px;

	&:first-child { margin-top: 0; }
	&:last-child  { margin-bottom: 0; }

	.accordion-item
	{
		position: relative;
		margin-top: 10px;
		margin-bottom: 10px;

		&:first-child { margin-top: 0; }
		&:last-child  { margin-bottom: 0; }

		&:first-child
		{
			article { display: block; }
		}

		article
		{
			display: none;
			position: relative;

			p
			{
				margin-top: 15px;
				margin-bottom: 15px;

				&:first-child { margin-top: 0; }
				&:last-child  { margin-bottom: 0; }
			}
		}

		.inner { padding: 15px 30px 5px; }

		&.active .accordion-toggler
		{
			background-color: $theme-color;
			color: #fff;
			cursor: default;
		}
	}

	.accordion-toggler
	{
		line-height: 1.1;
		font-size: 18px;
		font-weight: 400;
		font-family: $base-fontFamily-2;
		background-color: #e4e4e4;
		padding: 18px 30px;
		margin: 0;
		cursor: pointer;
		@include transition(background-color 0.3s ease-in-out, color 0.3s ease-in-out);

		@include max-screen(480px)
		{
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}