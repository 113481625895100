/* --------------------------------
   custom bxslider styles
-------------------------------- */

.bxslider-container
{
	position: relative;
	z-index: 2;

	.bx-wrapper
	{
		background: none;
		border: none;
		box-shadow: none;
		margin-bottom: 0;
	}

	.control-btn
	{
		position: absolute;
		top: 50%;
		margin-top: -15px;

		&.prev-btn { left: 0; }
		&.next-btn { right: 0; }

		a
		{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
}

.bx-wrapper .bx-loading
{
	background-image: url(../img/bx_slider_img/bx_loader.gif);
}