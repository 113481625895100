/* --------------------------------
   partners
-------------------------------- */

.s-partners
{
	position: relative;
	padding-top: 50px;
	padding-bottom: 50px;

	&.partners-style-1
	{
		background-color: #41464e;

		.bxslider-container
		{
			padding-left: 50px;
			padding-right: 50px;
		}
	}

	&.partners-style-2
	{
		background-color: rgba($theme-color,0.1);

		@include min-screen($sm-width+1px) { padding-top: 80px; }

		.home &
		{
			padding-top: 0;

			&:before
			{
				content: "";
				position: absolute;
				bottom: 100%;
				left: 0;
				width: 100%;
				height: 155px;
				background-color: rgba($theme-color,0.1);
				z-index: -1;
			}
		}

		.bxslider-container
		{
			border-bottom: 1px solid #f4f1ec;
			padding-top: 15px;
			padding-bottom: 30px;
		}

		.control-wrp
		{
			position: absolute;
			top: 0;
			right: 0;
			margin-top: -50px;
		}

		.control-btn
		{
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			margin-top: 0;
		}
	}
}