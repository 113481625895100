/* --------------------------------
   single post
-------------------------------- */

#s-single-post
{
	.pattern
	{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 35%;
		background-color: #fafafa;
	}

	.sep
	{
		display: block;
		width: 100%;
		border-top: 3px solid $theme-color;
	}
}

#single-post--container
{
	@include max-screen($md-width)
	{
		[class^="col-xs"]:not(:last-child)>&,
		[class*="col-xs"]:not(:last-child)>&
		{
			margin-bottom: 40px;
		}
	}

	.sep
	{
		@include min-screen($md-width+1px)
		{
			margin-top: 80px;
			margin-bottom: 80px;
		}

		@include screen($sm-width+1px,$md-width)
		{
			margin-top: 65px;
			margin-bottom: 65px;
		}

		@include max-screen($sm-width)
		{
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}

	img
	{
		margin-top: 30px;
		margin-bottom: 30px;

		&:first-child { margin-top: 0; }
		&:last-child { margin-bottom: 0; }
	}

	.date-post
	{
		& + .title
		{
			margin-top: 10px;
			margin-bottom: 10px;

			& + .meta { margin-top: 10px; }
		}
	}

	.title
	{
		& + .meta { margin-top: -10px; }
	}

	.meta
	{
		line-height: 1;
		margin-left: -10px;

		span
		{
			display: inline-block;
			vertical-align: middle;
			line-height: 1.2;
			font-size: 10px;
			text-transform: uppercase;
			color: #bdbdbd;
			margin-left: 10px;

			a { color: inherit; }
		}

		i
		{
			display: inline-block;
			margin-right: 5px;
		}
	}

	.quote
	{
		background-color: #efefef;
		line-height: 1.25;
		font-family: $base-fontFamily-2;
		padding: 15px 20px;
		margin-top: 20px;
		margin-bottom: 20px;

		&:first-child { margin-top: 0; }
		&:last-child { margin-bottom: 0; }

		@include min-screen($md-width+1px)
		{
			font-size: 35px;
		}

		@include screen($sm-width+1px,$md-width)
		{
			font-size: 30px;
		}

		@include max-screen($sm-width)
		{
			font-size: 25px;
		}

		.inner
		{
			width: 100%;
			max-width: 690px;
			padding: 5% 0;
		}
	}

	&
	{
		[class^="col-xs"]>.list,
		[class*="col-xs"]>.list,
		[class^="col-sm"]>.list,
		[class*="col-sm"]>.list
		{
			margin-top: 0;
		}
	}

	.list
	{
		line-height: 1.4;
		margin-top: 30px;
		margin-bottom: 30px;

		li
		{
			margin-top: 15px;
			margin-bottom: 15px;
			padding-left: 30px;

			&:first-child { margin-top: 0; }
			&:last-child { margin-bottom: 0; }
		}

		i
		{
			float: left;
			color: $theme-color;
			margin-left: -30px;
		}
	}

	.post-comments { position: relative; }

	.post-comments--list
	{
		.children
		{
			margin-left: 40px;

			@include max-screen(480px) { margin-left: 20px; }
		}

		.author,
		.date-post
		{
			display: inline-block;
			vertical-align: baseline;
			font-weight: 700;
		}

		.author
		{
			font-size: 16px;
			font-family: $base-fontFamily-2;
			margin-right: 10px;
		}

		.date-post
		{
			font-size: 10px;
			color: #cdcdcd;
		}

		.reply
		{
			position: absolute;
			top: 15px;
			right: 15px;
			line-height: 1;
			font-size: 16px;
			transform: scale(-1, 1);

			&:hover { color: #828282; }
		}
	}

	.post-comment--wrap
	{
		position: relative;
		padding: 30px 40px;
		margin-top: 10px;
		border: 1px solid #efefef;


		@include screen(481px,$sm-width) { padding: 25px 30px; }
		@include max-screen(480px) { padding: 20px 15px; }
	}

	form
	{
		.input-wrp
		{
			input,
			textarea
			{
				color: #a6a6a6;

				&::-webkit-input-placeholder { color:#a6a6a6; }
				&::-moz-placeholder          { color:#a6a6a6; }
				&:-moz-placeholder           { color:#a6a6a6; }
				&:-ms-input-placeholder      { color:#a6a6a6; }
			}

			textarea { min-height: 155px; }

			input:hover + span,
			input:focus + span,
			textarea:hover + span,
			textarea:focus + span
			{
				border-color: #7b7b7b;
			}
		}
	}

	.team-intro
	{
		@include min-screen($md-width+1px) { margin-bottom: 35px; }

		.name
		{
			line-height: 1;
			font-size: 35px;
			margin-bottom: 5px;
		}

		.position
		{
			line-height: 1;
			text-transform: uppercase;
			color: #9b9b9b;
			margin-top: 10px;
		}
	}

	.timeline
	{
		margin-top: 50px;
		margin-bottom: 50px;

		&:first-child { margin-top: 0; }
		&:last-child { margin-bottom: 0; }

		.item
		{
			position: relative;
			padding-bottom: 40px;

			@include min-screen($sm-width+1px) { padding-left: 70px; }
			@include screen(651px,$sm-width)   { padding-left: 50px; }
			@include max-screen(650px)         { padding-left: 35px; }

			&:last-child
			{
				padding-bottom: 0;

				&:before { content: none; }
			}

			&:before
			{
				content: "";
				position: absolute;
				top: 0;
				left: 6px;
				width: 3px;
				height: 100%;
				border-left: 1px solid #dbdee3;
				border-right: 1px solid #dbdee3;
			}

			>i
			{
				position: absolute;
				top: 0;
				left: 0;
				width: 16px;
				height: 16px;
				background-color: #fff;
				border: 2px solid #dbdee3;

				&:before
				{
					content: "";
					position: absolute;
					top: 3px;
					right: 3px;
					bottom: 3px;
					left: 3px;
					background-color: $theme-color;
					@include border-radius(50%);
				}
			}
		}

		.title { color: #444; }

		.date
		{
			display: block;
			line-height: 1.2;
			font-size: 12px;
			font-weight: 700;
			color: $theme-color;
		}

		p
		{
			margin-top: 10px;

			&:first-child { margin-top: 0; }
		}
	}

	.social-btns
	{
		margin-top: 20px;
		margin-bottom: 20px;

		&:first-child { margin-top: 0; }
		&:last-child { margin-bottom: 0; }

		a
		{
			color: #c4c4c4;

			&:hover { color: $theme-color; }
		}
	}
}