a
{
	color: $theme-color;
	outline: none;
	@include transition(color 0.3s ease-in-out);

	&:hover,
	&:focus
	{
		color: $theme-color;
		text-decoration: none;
	}
}

.h1,h1,
.h2,h2,
.h3,h3,
.h4,h4,
.h5,h5
{
	line-height: 1.1;
	font-weight: 700;
	font-family: $base-fontFamily-2;
	@include transition(color 0.3s ease-in-out);

	&:first-child { margin-top: 0; }
	&:last-child  { margin-bottom: 0; }

	main & { color: #333; }

	a { color: inherit; }
}

.h1,h1 { font-size: 40px; }
.h2,h2 { font-size: 30px; }
.h3,h3 { font-size: 20px; }
.h4,h4 { font-size: 16px; }
.h5,h5 { font-size: 13px; }

.h1,h1
{
	margin-top: 25px;
	margin-bottom: 25px;

	&.h2,
	&.h3
	{
		margin-top: 20px;
		margin-bottom: 20px;
	}

	&.h4,
	&.h5
	{
		margin-top: 15px;
		margin-bottom: 15px;
	}
}

.h2,h2,
.h3,h3
{
	margin-top: 20px;
	margin-bottom: 20px;

	&.h4,
	&.h5
	{
		margin-top: 15px;
		margin-bottom: 15px;
	}
}

.h4,h4,
.h5,h5
{
	margin-top: 15px;
	margin-bottom: 15px;

	&.h2,
	&.h3
	{
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

.h5,h5
{
	font-family: $base-fontFamily-1;
	font-weight: 400;
}

.s-title
{
	line-height: 1.3;
	font-size: 16px;
	font-family: $base-fontFamily-2;
	color: #9b9b9b;
	text-align: center;
	padding-top: 98px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	width: 100%;
	max-width: 580px;

	&:first-child
	{
		padding-top: 0;
		margin-top: -2px;
	}

	p { margin-top: 25px; }
}

main
{
	p
	{
		margin-top: 20px;
		margin-bottom: 20px;

		&:first-child { margin-top: 0; }
		&:last-child  { margin-bottom: 0; }
	}
}

.date-post
{
	display: inline-block;
	line-height: 1.1;
	font-size: 11px;
	text-transform: uppercase;
	color: $theme-color;
}