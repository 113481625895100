/* --------------------------------
   skills
-------------------------------- */

.skills-container
{
	margin: 20px 0;

	&:first-child { margin-top: 0; }
	&:last-child  { margin-bottom: 0; }

	.skill-item
	{
		position: relative;
		margin-top: 10px;

		&:first-child { margin-top: 0; }

		> span
		{
			display: block;
			line-height: 1;
		}

		.progress-bar
		{
			height: 15px;
			margin-top: 5px;

			span
			{
				width: 100%;
				border: 2px solid #dbdee3;

				&:first-child
				{
					width: 0;
					border: none;
					background-color: $theme-color;
				}
			}
		}
	}
}