/* --------------------------------
   video
-------------------------------- */

.s-video
{
	position: relative;

	@include min-screen($lg-width+1px)
	{
		margin-left: 50px;
		margin-right: 50px;
	}

	@include screen($md-width+1px,$lg-width)
	{
		margin-left: 35px;
		margin-right: 35px;
	}

	@include screen($sm-width+1px,$md-width)
	{
		margin-left: 20px;
		margin-right: 20px;
	}

	.b-video
	{
		@include min-screen($sm-width+1px) { height: 410px; }
		@include max-screen($sm-width)     { height: 385px; }
	}
}

.b-video
{
	position: relative;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 30px 15px;
	line-height: 1;
	text-align: center;
	color: #fff;
	height: 100%;

	.pattern
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #4a5b74;
		opacity: .80;
	}

	.btn-play-container
	{
		position: relative;
		display: inline-block;
		width: auto;
		font-size: 20px;

		span
		{
			position: absolute;
			top: 27px;
			right: 100%;
			margin-right: 20px;
			white-space: nowrap;

			@include max-screen(550px)
			{
				position: relative;
				display: block;
				top: auto;
				right: auto;
				margin-top: 15px;
				margin-right: 0;
			}
		}
	}

	.btn-play
	{
		display: inline-block;
		vertical-align: top;
		width: 57px;
		height: 74px;
		background-image: url(../img/ico/ico_play.png);
		background-size: 100% 100%;
	}
}