/* --------------------------------
   faq list
-------------------------------- */

.faq-list
{
	margin-top: 20px;
	margin-bottom: 20px;

	&:first-child { margin-top: 0; }
	&:last-child  { margin-bottom: 0; }

	li
	{
		position: relative;
		line-height: 1;
		padding-left: 40px;
		margin-top: 20px;
		margin-bottom: 20px;

		&:first-child { margin-top: 0; }
		&:last-child  { margin-bottom: 0; }
	}

	i
	{
		position: relative;
		display: block;
		float: left;
		margin-left: -40px;
		width: 21px;
		height: 21px;
		border: 2px solid $theme-color;

		&:before,
		&:after
		{
			content: "";
			position: absolute;
			background-color: $theme-color;
		}

		&:before
		{
			width: 9px;
			height: 3px;
			left: 4px;
			top: 7px;
		}

		&:after
		{
			width: 3px;
			height: 9px;
			left: 7px;
			top: 4px;
		}
	}

	a
	{
		line-height: 21px;
		font-size: 18px;
		font-family: $base-fontFamily-2;
		color: inherit;

		&:hover { color: $theme-color; }
	}
}