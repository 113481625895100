/* --------------------------------
   home slider
-------------------------------- */

#start-screen { position: relative; }

.hero-slider
{
	position: relative;
	height: 100%;
	overflow: hidden;
	color: #fff;

	li
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: translateX(100%);
		text-align: center;

		&.selected
		{
			/* this is the visible slide */
			position: relative;
			transform: translateX(0);
		}

		&.move-left
		{
			/* slide hidden on the left */
			transform: translateX(-100%);
		}

		&.is-moving,
		&.selected
		{
			/* the is-moving class is assigned to the slide which is moving outside the viewport */
			@include transition(transform .5s);
		}
	}
}

/* -------------------------------- 
	Single slide style
-------------------------------- */

.hero-slider
{
	li
	{
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		.container { height: 100%; }

		p
		{
			font-size: 14px;
			margin-top: 20px;
			margin-bottom: 20px;

			&:first-child { margin-top: 0; }
			&:last-child { margin-bottom: 0; }
		}
	}

	.full-width
	{
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		left: 0;
		top: 0;
		padding-top: 20px;
		padding-bottom: 20px;

		backface-visibility: hidden;
		transform: translateZ(0);
	}

	.bg-video-wrapper
	{
		/* hide video on mobile device */
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		video
		{
			/* you won't see this element in the html, but it will be injected using js */
			display: block;
			min-height: 100%;
			min-width: 100%;
			max-width: none;
			height: auto;
			width: auto;
			position: absolute;
			// @include center;
		}
	}

	.custom-btn { text-transform: uppercase; }
}

/* --------------------------------
	Single slide animation
-------------------------------- */

@include min-screen($sm-width+1px)
{
	.hero-slider
	{
		li .full-width h2,
		li .full-width p,
		li .full-width .custom-btn
		{
			opacity: 0;
			transform: translateX(100px);
		}

		li.move-left .full-width h2,
		li.move-left .full-width p,
		li.move-left .full-width .custom-btn
		{
			opacity: 0;
			transform: translateX(-100px);
		}

		li.selected .full-width h2,
		li.selected .full-width p,
		li.selected .full-width .custom-btn
		{
			/* this is the visible slide */
			opacity: 1;
			transform: translateX(0);
		}

		li.is-moving .full-width h2,
		li.is-moving .full-width p,
		li.is-moving .full-width .custom-btn
		{
			/* this is the slide moving outside the viewport 
			wait for the end of the transition on the li parent before set opacity to 0 and translate to 100px/-100px */
			@include transition(opacity 0s .5s, transform 0s .5s);
		}

		li.selected h2
		{
			@include transition(opacity .4s .2s, transform .5s .2s);
		}

		li.selected p
		{
			@include transition(opacity .4s .3s, transform .5s .3s);
		}

		li.selected .custom-btn
		{
			@include transition(opacity .4s .4s, transform .5s .4s, background-color .2s 0s);
		}
	}
}

/* -------------------------------- 
	Slider navigation
-------------------------------- */

.slider-nav
{
	position: absolute;
	z-index: 2;

	nav
	{
		position: relative;
		display: inline-block;
	}

	ul
	{
		&.horizontal li
		{
			display: inline-block;
			float: left;
			margin-left: 2px;
			margin-right: 2px;
		}

		&.vertical li
		{
			display: block;
			margin-top: 4px;
			margin-bottom: 4px;
		}
	}

	li
	{
		&.selected a
		{
			cursor: default;
			border-color: $theme-color;
			color: $theme-color;
		}
	}

	a
	{
		position: relative;
		display: block;
		width: 30px;
		height: 30px;
		background-color: transparent;
		border: 2px solid #fff;
		box-shadow: none;
		line-height: 26px;
		font-size: 12px;
		font-weight: 700;
		text-align: center;
		text-decoration: none;
		color: #fff;
		outline: none;
		@include border-radius(50%);
		@include transition(background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out);

		.no-touch &:hover
		{
			border-color: $theme-color;
			color: $theme-color;
		}
	}
}