/* --------------------------------
   portfolio
-------------------------------- */

#s-portfolio {}

#option-set
{
	line-height: 0;
	font-size: 0;
	letter-spacing: -1px;
	margin-left: -25px;
	margin-bottom: 15px;

	@include max-screen($sm-width) { text-align: center; }

	li
	{
		display: inline-block;
		vertical-align: top;
		margin-left: 25px;
		margin-bottom: 15px
	}

	a
	{
		display: inline-block;
		vertical-align: top;
		border: 2px solid transparent;
		border-radius: 20px;
		padding: 3px 15px;
		line-height: 1.2;
		font-size: 15px;
		font-family: $base-fontFamily-2;
		letter-spacing: 0;
		text-align: center;
		text-decoration: none;
		color: #333;
		@include transition(border-color 300ms ease-in-out, color 300ms ease-in-out);

		&.selected,
		&:hover
		{
			border-color: $theme-color;
			color: $theme-color;
		}
	}
}

.portfolio-container
{
	&.portfolio-four-columns.portfolio-style-1
	{
		.portfolio-item
		{
			.img-wrap { padding-top: percentage(285/270); }
		}
	}

	&.portfolio-three-columns.portfolio-style-2
	{
		.portfolio-item
		{
			.img-wrap { padding-top: percentage(317/370); }
		}
	}

	&.portfolio-style-1
	{
		.portfolio-item
		{
			.inner a
			{
				line-height: 1;
				color: #fff;
				padding: 20px;

				&:before
				{
					display: inline-block;
					vertical-align: bottom;
					content: "";
					width: 0;
					height: 100%;
				}
			}
		}
	}

	&.portfolio-style-2
	{
		.portfolio-item
		{
			.description
			{
				padding-top: 20px;
				color: #9b9b9b;
			}

			.title
			{
				color: #444;
				margin-bottom: 5px;
			}

			p { margin-top: 0; }

			&:hover
			{
				.title
				{
					a:hover { color: $theme-color; }
				}
			}
		}
	}

	.portfolio-container--inner
	{
		overflow: hidden;
		margin-bottom: -30px;
	}

	.portfolio-item
	{
		max-width: 500px;
		margin-bottom: 30px;

		.inner
		{
			position: relative;
			background-color: #4a5b74;
			@include transition(background-color 300ms ease-in-out);
		}

		.img-wrap
		{
			position: relative;
			overflow: hidden;
			width: 100%;
			height: 0;
			opacity: 0.5;
			@include transition(opacity 300ms ease-in-out);
		}

		img,
		.inner a
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			margin: 0;
		}

		a:hover { text-decoration: none; }

		&:hover
		{
			.inner:hover
			{
				background-color: $theme-color;

				.img-wrap { opacity: 0.25; }
			}
		}
	}

	#portfolio-more-btn { margin-top: 30px; }
}