/* --------------------------------
   custom bootstrap styles
-------------------------------- */

body
{
	font-family: $base-fontFamily-1;
	font-size: 14px;
	line-height: 1.538;
	color: #555;
	background-color: $white;
}

.container { position: relative; }

@media (min-width: 1200px) {
	/*
	if you use custom scroll remove this comment  
	.container { width: 1200px; }
	*/
}