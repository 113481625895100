*
{
	padding: 0;
	margin: 0;
}

ul, ol
{
	list-style: none;
	list-style-image: none;
}

iframe
{
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

/*
if you use custom scroll remove this comment  

html.js.desktop { overflow-y: hidden; }
*/

body { position: relative; }

/* parallax */

.parallax
{
	position: relative;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;

	.desktop & { background-attachment: fixed; }
}

/* float */

.fl-l { float: left; }
.fl-r { float: right; }

/* text align */

.text-center { text-align: center; }
.text-left   { text-align: left; }
.text-right  { text-align: right; }

/* vertical align */

.v-top    { vertical-align: top; }
.v-middle { vertical-align: middle; }
.v-bottom { vertical-align: bottom; }

.v-align
{
	height: 100%;

	&:before
	{
		content: "";
		width: 0;
		height: 100%;
		margin-left: -4px;
	}

	&:before,
	>*
	{
		display: inline-block;
		vertical-align: middle;
	}

	>* { width: 100%; }
}

.b-table
{
	display: table;
	width: 100%;

	.cell { display: table-cell; }
}

/* circled element */

.circled { @include border-radius(50%); }

.col-MB-30 { margin-bottom: 30px; }
.col-MB-25 { margin-bottom: 25px; }
.col-MB-20 { margin-bottom: 20px; }
.col-MB-15 { margin-bottom: 15px; }

@include max-screen($md-width)
{
	.col-md-MB-30 { margin-bottom: 30px; }
	.col-md-MB-25 { margin-bottom: 25px; }
	.col-md-MB-20 { margin-bottom: 20px; }
	.col-md-MB-15 { margin-bottom: 15px; }

	[class^="col-xs"]:last-child>.col-md-MB-30,
	[class*="col-xs"]:last-child>.col-md-MB-30,
	[class^="col-xs"].col-md-MB-30:last-child,
	[class*="col-xs"].col-md-MB-30:last-child,

	[class^="col-xs"]:last-child>.col-md-MB-25,
	[class*="col-xs"]:last-child>.col-md-MB-25,
	[class^="col-xs"].col-md-MB-25:last-child,
	[class*="col-xs"].col-md-MB-25:last-child,

	[class^="col-xs"]:last-child>.col-md-MB-20,
	[class*="col-xs"]:last-child>.col-md-MB-20,
	[class^="col-xs"].col-md-MB-20:last-child,
	[class*="col-xs"].col-md-MB-20:last-child,

	[class^="col-xs"]:last-child>.col-md-MB-15,
	[class*="col-xs"]:last-child>.col-md-MB-15,
	[class^="col-xs"].col-md-MB-15:last-child,
	[class*="col-xs"].col-md-MB-15:last-child
	{
		margin-bottom: 0;
	}
}

@include max-screen($sm-width)
{
	.col-sm-MB-30 { margin-bottom: 30px; }
	.col-sm-MB-25 { margin-bottom: 25px; }
	.col-sm-MB-20 { margin-bottom: 20px; }
	.col-sm-MB-15 { margin-bottom: 15px; }

	[class^="col-xs"]:last-child>.col-sm-MB-30,
	[class*="col-xs"]:last-child>.col-sm-MB-30,

	[class^="col-xs"]:last-child>.col-sm-MB-25,
	[class*="col-xs"]:last-child>.col-sm-MB-25,

	[class^="col-xs"]:last-child>.col-sm-MB-20,
	[class*="col-xs"]:last-child>.col-sm-MB-20,

	[class^="col-xs"]:last-child>.col-sm-MB-15,
	[class*="col-xs"]:last-child>.col-sm-MB-15,
	{
		margin-bottom: 0;
	}
}

/* section */

.section
{
	position: relative;
	padding-top: 60px;
	padding-bottom: 60px;

	&.transparent { background-color: transparent; }

	&.transparent + .section.transparent,
	&.transparent + .ancor + .section.transparent
	{
		padding-top: 0;
	}

	&.transparent.with-border
	{
		border-top: 1px solid #f0f0f0;
		padding-top: 59px !important;
	}

	@include min-screen($sm-width+1px)
	{
		padding-top: 80px;
		padding-bottom: 80px;

		&.transparent.with-border { padding-top: 79px !important; }
	}

	@include min-screen($md-width+1px)
	{
		padding-top: 100px;
		padding-bottom: 100px;

		&.transparent.with-border { padding-top: 99px !important; }
	}
}

/* embed responsive */

.embed-responsive
{
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;

	&-item,
	iframe,
	embed,
	object,
	video
	{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}

	&-21by9 { padding-bottom: 42.857143%; }
	&-16by9 { padding-bottom: 56.25%; }
	&-4by3  { padding-bottom: 75%;}
	&-1by1  { padding-bottom: 100%; }
}

/* rating star */

.rating
{
	position: relative;
	font-size: 0;
	line-height: 0;
	letter-spacing: -1px;
	margin-left: -2px;
	margin-right: -2px;

	i
	{
		display: inline-block;
		vertical-align: middle;
		margin-left: 2px;
		margin-right: 2px;
		width: 1em;
		line-height: 1;
		font-size: 15px;
		text-align: center;
		letter-spacing: 0;
		@include transition(color 0.3s ease-in-out);

		&.active { }
	}
}

/* social buttons */

.social-btns
{
	position: relative;
	font-size: 0;
	line-height: 0;
	letter-spacing: -1px;

	a
	{
		display: inline-block;
		vertical-align: bottom;
		text-align: center;
		letter-spacing: 0;
		text-decoration: none;
		@include transition(background-color 0.3s ease-in-out, color 0.3s ease-in-out);
	}

	&.style-1
	{
		margin-left: -5px;
		margin-right: -5px;

		a
		{
			width: 1em;
			margin-left: 5px;
			margin-right: 5px;
			line-height: 1;
			font-size: 16px;
			color: $theme-color;

			&:hover { color: #fff; }
		}
	}

	&.style-2
	{
		a
		{
			width: 28px;
			height: 28px;
			margin-left: 2px;
			margin-right: 2px;
			line-height: 28px;
			color: #fff;
			font-size: 11px;

			&.fb  { background-color: #3b5998; }
			&.tw  { background-color: #45b0e3; }
			&.ggl { background-color: #de4c34; }
		}
	}
}

/* go bottom button */

#go-bottom
{
	position: absolute;
	bottom: 20px;
	left: 50%;
	width: 22px;
	margin-left: -11px;
	padding-top: 40px;
	opacity: 0.3;
	z-index: 3;
	@include transition(opacity 0.3s ease-in-out);

	&:hover { opacity: 1; }

	@include min-screen(481px)
	{
		bottom: 50px;
	}

	&:before
	{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 22px;
		height: 32px;
		background-image: url(../img/ico/ico_mouse.png);
		background-size: 100% 100%;
	}

	i
	{
		display: block;
		margin: auto;
		width: 14px;
		height: 14px;
		background-image: url(../img/ico/ico_arrows.png);
		background-size: 100% 100%;
		opacity: 1;
		animation-name: bounce;
		animation-duration: 1.5s;
		animation-fill-mode: both;
		animation-iteration-count: infinite;
	}
}

/* top button */

#btn-to-top-wrap
{
	display: none;
	position: fixed;
	right: 25px;
	bottom: 25px;
	z-index: 5;
}

#btn-to-top
{
	position: relative;
	display: block;
	width: 44px;
	height: 44px;
	line-height: 38px;
	font-size: 20px;
	color: #fff;
	text-align: center;
	text-decoration: none;
	background-color: $theme-color;
	opacity: 0.7;
	@include transition(opacity 0.3s ease-in-out);

	&:before
	{
		display: inline-block;
		vertical-align: middle;
		content: '';
		height: 0;
		width: 0;
		border-bottom: 6px solid #fff;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
	}

	&:hover { opacity: 1; }
}

.ancor { display: block; }

@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100%
	{
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	40%
	{
		-webkit-transform: translateY(10px);
		transform: translateY(10px);
	}

	60%
	{
		-webkit-transform: translateY(5px);
		transform: translateY(5px);
	}
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100%
	{
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}

	40%
	{
		-webkit-transform: translateY(10px);
		-ms-transform: translateY(10px);
		transform: translateY(10px);
	}

	60%
	{
		-webkit-transform: translateY(5px);
		-ms-transform: translateY(5px);
		transform: translateY(5px);
	}
}