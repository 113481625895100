/* --------------------------------
   tabs
-------------------------------- */

.tab-container
{
	margin-top: 20px;
	margin-bottom: 20px;

	&:first-child { margin-top: 0; }
	&:last-child  { margin-bottom: 0; }

	nav
	{
		line-height: 0;
		font-size: 0;
		letter-spacing: -1px;
		z-index: 2;

		@include min-screen($sm-width+1px) { border-left: 2px solid #dbdee3; }

		a
		{
			position: relative;
			display: block;
			width: 100%;
			
			padding: 18px 10px;
			margin-top: -2px;
			border: 2px solid #dbdee3;
			line-height: 1.2;
			font-size: 13px;
			font-weight: 700;
			letter-spacing: 0;
			text-align: center;
			color: #555;
			@include transition(background-color 300ms ease-in-out, color 300ms ease-in-out);

			&:first-child { margin-top: 0; }

			&.active
			{
				background-color: $theme-color;
				border-color: $theme-color;
				color: #fff;
				cursor: default;
				z-index: 3;
			}

			@include min-screen($sm-width+1px)
			{
				display: inline-block;
				vertical-align: top;
				max-width: 170px;
				margin-top: 0;
				margin-left: -2px;
			}
		}
	}

	.tab-content
	{
		position: relative;
		border: 2px solid #dbdee3;
		margin-top: -2px;
		padding-top: 2px;
	}

	.tab-item
	{
		position: absolute;
		top: 2px;
		left: 0;
		visibility: hidden;
		opacity: 0;
		padding: 20px;
		@include transition(opacity 300ms ease-in-out, visibility 300ms ease-in-out);

		&.visible
		{
			position: relative;
			top: auto;
			left: auto;
			visibility: visible;
			opacity: 1;
			z-index: 2;
		}

		@include min-screen($sm-width+1px) { padding-top: 25px; }

		p { margin-bottom: 15px; }

		*:last-child { margin-bottom: 0; }
	}
}

.special-tabs
{
	position: relative;
	padding-top: 40px;
	text-align: center;
	color: #fff;

	@include min-screen($sm-width+1px) { padding-top: 70px; }

	.pattern
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #4a5b74;
		opacity: 0.75;
	}

	.title { color: inherit; }

	.tab-container
	{
		.tab-content
		{
			border: none;
			max-width: 970px;
			margin: 0 auto 40px;
			padding-top: 0;

			@include min-screen($sm-width+1px) { margin-top: 65px; }
		}

		.tab-item
		{
			top: 0;
			font-size: 14px; 
		}

		nav
		{
			border-left: none;

			a
			{
				margin-top: 0;
				margin-left: 0;
				background: none !important;
				border: none !important;
				font-size: 20px;
				font-family: $base-fontFamily-2;
				color: inherit;

				&:before,
				&:after
				{
					content: "";
				}

				&:before
				{
					display: inline-block;
					vertical-align: middle;
					width: 0;
					height: 100%;
				}

				&:after
				{
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(#252e3a,0.5);
					@include transition(height 300ms ease-in-out);
				}

				span
				{
					position: relative;
					display: inline-block;
					vertical-align: middle;
					z-index: 2;
				}

				&.active
				{
					&:after { height: 0; }
				}

				@include min-screen($sm-width+1px)
				{
					max-width: 33.3%;
					height: 180px;
					padding-top: 60px;
					padding-bottom: 60px;

					&.active
					{
						&:after { height: 10px; }

						.ico
						{
							transform: translate(-50%) scale(1);
							opacity: 1;
						}
					}
				}

				@include screen(481px,$sm-width)
				{
					height: 130px;
				}
			}

			.ico
			{
				position: relative;
				display: none;
				margin-right: 20px;
				line-height: 1;
				font-size: 50px;
				z-index: 2;
				@include transition(transform 300ms ease-in-out, opacity 300ms ease-in-out 50ms);

				@include min-screen($sm-width+1px)
				{
					position: absolute;
					top: 0;
					left: 50%;
					margin-right: 0;
					transform: translate(-50%) scale(0.5);
					opacity: 0;
				}

				@include min-screen(481px)
				{
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
}