/* --------------------------------
   pricing table
-------------------------------- */

.pricing-table-container
{
	@include min-screen($sm-width) { padding-top: 30px; }

	.pricing-table-container--inner { margin-bottom: -30px; }

	.price-item
	{
		position: relative;
		overflow: hidden;
		border: 6px solid #44474b;
		text-align: center;
		margin-bottom: 30px;
		padding: 65px 35px 50px;
		max-width: 400px;

		@include max-screen(380px) { padding: 45px 15px 30px; }

		.sale
		{
			display: block;
			position: absolute;
			left: -79px;
			top: 0;
			line-height: 1;
			font-size: 16px;
			font-weight: 700;
			color: $white;
			background-color: #44474b;
			width: 110px;
			height: 110px;
			padding-top: 80px;
			padding-bottom: 10px;
			transform: rotate(-45deg);
			transform-origin: top left;
		}

		.price
		{
			margin-bottom: 50px;

			@include max-screen(480px) { margin-bottom: 30px; }

			span
			{
				display: inline-block;
				vertical-align: bottom;
				margin: 0 3px;

				@include max-screen(480px) { display: block; }
			}

			p
			{
				line-height: 1;
				color: #9b9b9b;
				padding-top: 10px;
				margin-top: -1px;
			}
		}

		.old-price
		{
			line-height: 1.5;
			font-size: 28px;
			color: #44474b;
			text-decoration: line-through;
		}

		.new-price
		{
			line-height: 1;
			font-size: 80px;
			font-weight: 700;
			color: $theme-color;

			sup
			{
				font-size: 45px;
				top: -.55em;
			}
		}

		.dexcription
		{
			h3
			{
				font-size: 25px;

				@include min-screen(481px) { margin-bottom: 40px; }
			}

			.custom-btn { margin-top: 20px; }
		}

		&.active
		{
			border-color: $theme-color;

			@include min-screen($sm-width)
			{
				margin-top: -30px;
				padding-top: 95px;
				padding-bottom: 70px;
			}

			.sale
			{
				left: -110px;
				font-size: 20px;
				background-color: $theme-color;
				width: 150px;
				height: 150px;
				padding-top: 115px;
			}
		}
	}
}