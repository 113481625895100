@mixin border-radius($radius)
{
	-webkit-border-radius: $radius;
	-moz-border-radius:    $radius;
	-ms-border-radius:     $radius;
	border-radius:         $radius;
}

/* .box { @include border-radius(10px); } */

@mixin transition($properties...)
{
	@if length($properties) >= 1 {

		-moz-transition:    $properties;
		-o-transition:      $properties;
		-webkit-transition: $properties;
		transition:         $properties;
	}

	@else {
		-moz-transition:    all 0.2s ease-in-out 0s;
		-o-transition:      all 0.2s ease-in-out 0s;
		-webkit-transition: all 0.2s ease-in-out 0s;
		transition:         all 0.2s ease-in-out 0s;
	}
}

/* .box { @include transition(width, height 0.3s ease-in-out); } */

@mixin triangle($direction, $size: 6px, $color: #222)
{
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	@if ($direction == 'up'){
		border-bottom: $size solid $color;
		border-left: 1/2*$size solid transparent;
		border-right: 1/2*$size solid transparent;
	}
	@else if ($direction == 'down'){
		border-top: $size solid $color;
		border-left: 1/2*$size solid transparent;
		border-right: 1/2*$size solid transparent;
	}
	@else if ($direction == 'left'){
		border-top: 1/2*$size solid transparent;
		border-bottom: 1/2*$size solid transparent;
		border-right: $size solid $color;
	}
	@else if ($direction == 'right'){
		border-top: 1/2*$size solid transparent;
		border-bottom: 1/2*$size solid transparent;
		border-left: $size solid $color;
	}
}

@mixin retina
{
	@media
	only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min--moz-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 2/1),
	only screen and (min-device-pixel-ratio: 2),
	only screen and (min-resolution: 192dpi),
	only screen and (min-resolution: 2dppx) {
		@content;
	}
}