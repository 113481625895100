/* --------------------------------
   contact
-------------------------------- */

#s-contact
{
	.contact-item
	{
		margin-bottom: 30px;

		form
		{
			input,
			textarea
			{
				color: #555;

				&::-webkit-input-placeholder { color:#555; }
				&::-moz-placeholder          { color:#555; }
				&:-moz-placeholder           { color:#555; }
				&:-ms-input-placeholder      { color:#555; }
			}

			textarea { min-height: 150px; }
		}
	}

	.contact-item_info
	{
		article
		{
			margin-top: 28px;
			padding-top: 28px;
			border-top: 2px solid #f2f3f5;

			&:first-child
			{
				margin-top: 0;
				border-top: none;
			}
		}

		a
		{
			text-decoration: underline;

			&:hover { text-decoration: none; }
		}
	}

	.g_map
	{
		position: relative;
		height: 455px;
		margin-top: 25px;

		@include min-screen(481px) { margin-top: 55px; }
	}
}