/* --------------------------------
   blog
-------------------------------- */

#s-blog { }

.blog-container
{
	.blog-container--inner
	{
		overflow: hidden;
		margin-bottom: -30px;
	}

	.blog-item
	{
		position: relative;
		margin-bottom: 30px;
		z-index: 1;
		max-width: 500px;

		.inner
		{
			position: relative;
			background-color: #41464e;
			@include transition(background-color 300ms ease-in-out);

			&.fixed
			{
				.img-wrap { padding-top: 62.16216216216216%; }
			}

			&.fixed img,
			a
			{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		.img-wrap
		{
			position: relative;
			opacity: 0.44;
			@include transition(opacity 300ms ease-in-out);
		}

		img { width: 100%; }

		.description
		{
			padding-top: 20px;

			&:first-child { padding-top: 0; }
		}

		.date-post
		{
			& + .title
			{
				margin-top: 10px;
				margin-bottom: 10px;

				& + .meta { margin-top: 10px; }
			}
		}

		.title
		{
			& + .meta { margin-top: -10px; }
		}

		.meta
		{
			line-height: 1;
			margin-left: -10px;

			span
			{
				display: inline-block;
				vertical-align: middle;
				line-height: 1.2;
				font-size: 10px;
				text-transform: uppercase;
				color: #bdbdbd;
				margin-left: 10px;

				a { color: inherit; }
			}

			i
			{
				display: inline-block;
				margin-right: 5px;
			}
		}

		&:hover
		{
			.inner:hover
			{
				background-color: $theme-color;

				.img-wrap { opacity: 0.25; }
			}

			.title
			{
				a:hover { color: $theme-color; }
			}
		}
	}

	.control-btn { margin-top: 30px; }
}