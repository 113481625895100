/* --------------------------------
   buttons
-------------------------------- */

/* custom-btn */
.custom-btn
{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	background-color: transparent;
	border: 2px solid $theme-color;
	box-shadow: none;
	line-height: 1;
	font-weight: 700;
	font-family: $base-fontFamily-1;
	text-align: center;
	text-decoration: none;
	outline: none;
	overflow: hidden;
	z-index: 2;
	transform: translate3d(0,0,0);
	@include transition(background-color 0.3s, border-color 0.3s);

	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

	&.medium
	{
		min-width: 155px;
		font-size: 14px;
		@include border-radius(30px);

		&::after,
		span
		{
			padding: 16px 30px;
		}
	}

	&.small
	{
		min-width: 115px;
		font-size: 12px;
		@include border-radius(20px);

		&::after,
		span
		{
			padding: 10px 25px;
		}
	}

	&.inverted
	{
		background-color: $theme-color;
		color: #fff;

		&::after { color: #484848; }

		&:hover { background-color: transparent; }
	}

	&.dark-color { color: #484848; }
	&.light-color { color: #fff; }

	&::after,
	span
	{
		@include transition(opacity 0.3s, transform 0.3s);
		transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	}

	&::after
	{
		content: attr(data-text);
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		color: #fff;
		transform: translate3d(0, 30%, 0);
	}

	span { display: block; }

	&:hover
	{
		text-decoration: none;
		background-color: $theme-color;

		&::after
		{
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}

		span
		{
			opacity: 0;
			transform: translate3d(0, -30%, 0);
		}
	}
}

/* control-btn */
.control-btn
{
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 32px;
	height: 32px;
	background-color: transparent;
	border-width: 2px;
	border-style: solid;
	box-shadow: none;
	line-height: 28px;
	font-size: 13px;
	text-align: center;
	text-decoration: none;
	outline: none;
	@include border-radius(50%);
	@include transition(background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out);

	&.control-btn-style-1
	{
		border-color: $theme-color;
		color: $theme-color;

		&:hover
		{
			background-color: $theme-color;
			color: #fff;
		}
	}

	&.control-btn-style-2
	{
		border-color: $theme-color;
		color: $theme-color;

		&:hover
		{
			border-color: #fff;
			color: #fff;
		}

		&.invers
		{
			border-color: #fff;
			color: #fff;

			&:hover
			{
				border-color: $theme-color;
				color: $theme-color;
			}
		}
	}

	&.control-btn-style-3
	{
		border-color: #fff;
		color: #fff;

		&:hover
		{
			background-color: #fff;
			color: $theme-color;
		}
	}
}