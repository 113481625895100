/* main colors */
$white       : #FFF;
$theme-color : #c1aa81;

/* main fonts */
$base-fontFamily-1 : 'Oxygen', sans-serif;
$base-fontFamily-2 : 'Rufina', serif;

/* main breakpoint */
$lg-width: 1199px;
$md-width: 991px;
$sm-width: 767px;