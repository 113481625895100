/* --------------------------------
   footer
-------------------------------- */

#footer
{
	background-color: #41464e;
	color: $white;

	a:not([class]) { color: inherit; }

	&.footer-style-1
	{
		padding-top: 50px;
		padding-bottom: 15px;
		line-height: 1.5;

		.footer-form
		{
			.input-wrp
			{
				textarea { min-height: 70px; }
			}

			button[type="submit"] { margin-top: 7px; }
		}

		.copy
		{
			color: #b3b3b3;
			margin-top: 10px;
		}
	}

	&.footer-style-2
	{
		padding-top: 105px;
		padding-bottom: 50px;

		#footer-logo
		{
			margin-top: -15px;
			margin-bottom: 25px;
		}

		.footer-form
		{
			p { margin-bottom: 20px; }

			.b-table
			{
				.cell
				{
					width: 1%;

					&:first-child { width: 100%; }
				}
			}

			.input-wrp
			{
				margin-bottom: 0;
				margin-right: 10px;
			}
		}

		.copy
		{
			color: #b3b3b3;
			margin-top: 10px;
		}
	}

	&.footer-style-3
	{
		background-image: url(../img/bg/4.jpg);
		background-position: center bottom;
		background-size: cover;
		padding-top: 70px;
		padding-bottom: 40px;

		.footer-form
		{
			.input-wrp
			{
				input,
				textarea
				{
					color: #d6d6d6;

					&::-webkit-input-placeholder { color:#d6d6d6; }
					&::-moz-placeholder          { color:#d6d6d6; }
					&:-moz-placeholder           { color:#d6d6d6; }
					&:-ms-input-placeholder      { color:#d6d6d6; }
				}

				textarea
				{
					min-height: 170px;

					@include max-screen(480px) { min-height: 100px; }
				}

				span
				{
					border-color: rgba(#fff,0.5);
				}
			}

			button[type="submit"] { margin-top: 10px; }
		}

		.copy
		{
			color: #dedede;
			margin-top: 20px;
		}
	}

	.footer-item
	{
		position: relative;
		margin-bottom: 30px;
	}

	address
	{
		font-style: normal;

		span { display: block; }
	}

	.title
	{
		font-weight: 400;
		margin-bottom: 15px;
	}

	.list
	{
		li
		{
			margin-top: 15px;
			margin-bottom: 15px;
			padding-left: 20px;

			&:first-child { margin-top: 0; }
			&:last-child { margin-bottom: 0; }
		}

		i
		{
			float: left;
			font-size: 12px;
			color: $theme-color;
			margin-left: -20px;
		}

		a
		{
			text-decoration: underline;

			&:hover { text-decoration: none; }
		}
	}

	.recent-posts
	{
		position: relative;
		margin-top: 10px;
		line-height: 1.5;

		.date-post
		{
			display: block;
			margin-top: 2px;
		}
	}

	.footer-form
	{
		.input-wrp
		{
			margin-bottom: 8px;

			input,
			textarea
			{
				color: #838383;
				padding: 5px 10px;

				&::-webkit-input-placeholder { color:#838383; }
				&::-moz-placeholder          { color:#838383; }
				&:-moz-placeholder           { color:#838383; }
				&:-ms-input-placeholder      { color:#838383; }

				&:hover,
				&:focus
				{
					color: #cacaca;

					&::-webkit-input-placeholder { color:#cacaca; }
					&::-moz-placeholder          { color:#cacaca; }
					&:-moz-placeholder           { color:#cacaca; }
					&:-ms-input-placeholder      { color:#cacaca; }
				}
			}

			span { border-color: #676767; }

			input:hover + span,
			input:focus + span,
			textarea:hover + span,
			textarea:focus + span
			{
				border-color: #cacaca;
			}
		}
	}

	.social-btns { margin-top: 25px; }
}

#footer-logo
{
	width: 227px;
	height: 46px;
	margin-bottom: 5px;
	background-image: url(../img/logo_3.png);
}