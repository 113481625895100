/* --------------------------------
   feedback
-------------------------------- */

#s-feedback
{
	position: relative;
	padding-top: 60px;
	padding-bottom: 60px;
	color: #fff;

	&.feedback-style-1
	{
		.pattern
		{
			background-color: #4a5b74;
			opacity: 0.75;
		}
	}

	&.feedback-style-2
	{
		.pattern
		{
			background-color: #3c424a;
			opacity: 0.86;

			&.half { opacity: 0.58; }
		}

		.item
		{
			&.first
			{
				@include screen($sm-width+1px,$md-width) { margin-bottom: 50px; }
				@include max-screen($sm-width) { margin-bottom: 40px; }
			}

			&.second
			{
				@include min-screen($md-width) { padding-left: 30px; }
			}
		}
	}

	&.feedback-style-3
	{
		.pattern
		{
			background-color: #3c424a;
			opacity: 0.95;
		}
	}

	.pattern
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&.half
		{
			width: 50%;
			padding-right: 15px;
			box-sizing: content-box;
		}
	}

	h2 { color: #fff; }

	form
	{
		.input-wrp
		{
			input,
			textarea
			{
				color: #d6d6d6;

				&::-webkit-input-placeholder {color:#d6d6d6;}
				&::-moz-placeholder          {color:#d6d6d6;}
				&:-moz-placeholder           {color:#d6d6d6;}
				&:-ms-input-placeholder      {color:#d6d6d6;}
			}

			textarea { min-height: 145px; }

			span { border-color: fade-out(#fff,0.5); }
		}
	}
}

.feedback-bxslider-container
{
	text-align: center;
	padding-left: 50px;
	padding-right: 50px;

	@include min-screen($sm-width+1px) { padding-top: 60px; }
	@include max-screen($sm-width)     { padding-top: 40px; }

	&:before
	{
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		width: 30px;
		height: 22px;
		margin-left: -15px;
		background-image: url(../img/ico/ico_testimonial.png);
		background-size: 100% 100%;
	}

	.text
	{
		font-size: 14px;

		p { margin-bottom: 15px; }
	}

	.rating
	{
		@include min-screen($sm-width+1px)
		{
			margin-top: 35px;
			margin-bottom: 35px;
		}

		@include max-screen($sm-width)
		{
			margin-top: 20px;
			margin-bottom: 20px;
		}

		i
		{
			color: #828e9e;

			&.active { color: $theme-color; }
		}
	}

	.author
	{
		line-height: 1.2;

		@include min-screen($sm-width+1px) { margin-top: 35px; }
		@include max-screen($sm-width)     { margin-top: 20px; }

		img
		{
			display: inline-block;
			vertical-align: top;
			line-height: 1;
			width: 84px;
			height: 84px;
			margin-bottom: 15px;
		}

		.name,
		.position
		{
			margin-top: 0;
			margin-bottom: 0;
			color: inherit;
		}
	}
}