/* --------------------------------
   sidebar
-------------------------------- */

#sidebar
{
	position: relative;

	.sep
	{
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.widget
	{
		position: relative;
		margin-top: 30px;
		margin-bottom: 30px;

		&:first-child { margin-top: 0; }
	}

	.widget-title {}

	.widget-search
	{
		.input-wrp
		{
			input
			{
				color: #b1b1b1;

				&::-webkit-input-placeholder { color: #b1b1b1; }
				&::-moz-placeholder          { color: #b1b1b1; }
				&:-moz-placeholder           { color: #b1b1b1; }
				&:-ms-input-placeholder      { color: #b1b1b1; }
			}
		}
	}

	.widget-categories
	{
		ul
		{
			line-height: 1;
			font-size: 11px;
			font-weight: 700;

			li
			{
				border-top: 1px solid #ececec;
				padding-top: 11px;
				padding-bottom: 12px;

				&:first-child
				{
					border-top: none;
					padding-top: 12px;
				}

				span
				{
					float: right;
					line-height: 16px;
				}

				&.active a,
				& a:hover
				{
					color: $theme-color;
				}
			}

			a
			{
				display: inline-block;
				vertical-align: top;
				line-height: 16px;
				text-transform: uppercase;
				color: #555;
				margin-right: 10px;
			}

			i
			{
				display: inline-block;
				vertical-align: top;
				width: 16px;
				height: 16px;
				border: 2px solid $theme-color;
				line-height: 12px;
				font-size: 8px;
				text-align: center;
				color: $theme-color;
				margin-right: 10px;
			}
		}
	}

	.widget-posts
	{
		article
		{
			position: relative;
			border-top: 1px solid #ececec;
			padding-top: 19px;
			margin-top: 20px;
			margin-bottom: 20px;

			&:first-child
			{
				border-top: none;
				padding-top: 20px;
			}

			.inner
			{
				position: relative;
				overflow: hidden;
			}
		}

		.title
		{
			font-family: $base-fontFamily-2;
			font-weight: 700;
			margin-bottom: 5px;

			a
			{
				&:hover { color: #a2a2a1; }
			}
		}

		.link
		{
			display: block;
			float: left;
			margin-right: 15px;

			img
			{
				display: block;
				width: 70px;
				height: 60px;
			}
		}

		.date-post { font-size: 9px; }
	}

	.widget-practices
	{
		.practices-container
		{
			.practices-container--inner { margin-bottom: -30px; }

			.practices-item { margin-bottom: 30px; }
		}
	}

	.widget-tags
	{
		ul
		{
			margin-top: -5px;

			li
			{
				display: inline-block;
				vertical-align: top;
				margin-top: 5px;
			}

			a
			{
				vertical-align: top;
				text-transform: uppercase;
				min-width: 0;
			}
		}
	}

	.widget-team { }

	.widget-download
	{
		.download-btn
		{
			display: inline-block;
			vertical-align: top;
			font-size: 11px;
			font-weight: 700;
			text-decoration: underline;
			text-transform: uppercase;
			color: inherit;

			&:hover { text-decoration: none; }
		}

		.ico
		{
			display: inline-block;
			vertical-align: middle;
			width: 37px;
			height: 45px;
			background-size: 100% 100%;
			background-image: url(../img/ico/ico_file.png);
			margin-right: 15px;
		}
	}
}